<template>
	<div class="tags">
		<!-- 标签左按钮 -->
		<div class="btn left-btn" @click="leftScroll">
			<span>
				<i class="el-icon-arrow-left"></i>
			</span>
		</div>
		<!-- 标签行 -->
		<div class="tags-nav" id="tagsItem">
			<div v-for="(item,index) in tagsList" class="tags-item" :key="index">
				<span :class="['tag-circle',{'active': item.path === $route.path}]"></span>
				<router-link :to="item.path" class="tags-title">
					{{item.title}}
				</router-link>
				<span @click="closeTags(index)">
					<i class="el-icon-close"></i>
				</span>
			</div>
		</div>
		<!-- 标签右按钮 -->
		<div class="btn right-btn" @click="rightScroll">
			<span>
				<i class="el-icon-arrow-right"></i>
			</span>
		</div>

		<!-- 标签选项 -->
		<div class="btn tags-close-box">
			<el-dropdown trigger="hover" @command="handleTags">
				<i class="el-icon-circle-close" style="line-height: 32px;"></i>
				<el-dropdown-menu size="small" slot="dropdown">
					<el-dropdown-item command="other">关闭其他</el-dropdown-item>
					<el-dropdown-item command="all">关闭所有</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>

	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		computed: {
			...mapState({
				tagsList: state => state.tags.tagsList,
			}),
		},
		methods: {
			// 关闭单个标签
			closeTags(index) {
				let delTag = this.tagsList[index];
				this.$store.commit("deleteTag", {
					index
				});
				let item = this.tagsList[index] ?
					this.tagsList[index] :
					this.tagsList[index - 1];
				if (item) {
					delTag.path === this.$route.fullPath &&
						this.$router.push(item.path);
				} else {
					this.$router.push("/");
				}
			},

			// 关闭全部标签
			closeAllTags() {
				this.$store.commit("closeAllTags");
				this.$router.push("/");
			},

			// 关闭其他标签
			closeOtherTags() {
				let curTag = this.tagsList.filter(item => {
					return item.path === this.$route.fullPath;
				});
				this.$store.commit("closeOtherTags", curTag);
			},


			// 设置标签
			setTags(route) {
				let tagsList = this.tagsList
				const isExist = tagsList.some(item => {
					return item.path === route.fullPath;
				});
				if (!isExist) {
					tagsList.push({
						name: route.name,
						title: route.meta.title,
						path: route.fullPath
					})
					this.$store.commit("setTagsList", tagsList);
				}

			},

			// 标签选择操作
			handleTags(command) {
				command === "other" ? this.closeOtherTags() : this.closeAllTags();
			},

			// 向左滚动
			leftScroll() {
				var tagsRow = document.getElementById('tagsItem')
				tagsRow.scrollLeft = tagsRow.scrollLeft - 200
			},

			// 向右滚动
			rightScroll() {
				var tagsRow = document.getElementById('tagsItem')
				tagsRow.scrollLeft = tagsRow.scrollLeft + 200
			},
		},
		watch: {
			$route(newValue) {
				console.log(newValue)
				this.setTags(newValue);
			}
		},
		created() {
			this.setTags(this.$route);
		}
	};
</script>


<style>
	.tags {
		width: 100%;
		padding: 1rpx;
		height: 40px;
		box-sizing: border-box;
		background-color: #F5F7F9;
		display: flex;
		transition: all 1s ease-in;
	}

	.tags-nav {
		width: calc(100% - 95px);
		height: 40px;
		border-top: 3px solid #f0f0f0;
		border-bottom: 3px solid #f0f0f0;
		background-color: #F5F7F9;
		display: flex;
		align-items: center;
		padding: 0 5px;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: hidden;
		transition: all 1s ease-in;
	}

	.tags-item {
		border-radius: 3px;
		font-size: 12px;
		cursor: pointer;
		height: 32px;
		line-height: 32px;
		background: #fff;
		-webkit-transition: all 0.3s ease-in;
		-moz-transition: all 0.3s ease-in;
		transition: all 0.3s ease-in;
		border: 1px solid #e8eaec;
		color: #515a6e;
		padding: 0 12px;
		margin-right: 5px;
		display: flex;
		align-items: center;
		transition: all 1s ease-in;
	}

	.tag-circle {
		width: 12px;
		height: 12px;
		margin-right: 8px;
		border-radius: 50%;
		background: #e8eaec;
	}

	.active {
		color: #FFFFFF !important;
		background: #349ef9 !important;
	}

	.tags-title {
		max-width: 80px;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-right: 10px;
		color: #666;
	}

	.btn {
		height: 100%;
		background: #fff;
		z-index: 10;
		border: 1px solid #e2e2e2;
		width: 30px;
		line-height: 32px;
		text-align: center;
		padding: 3px;
	}

	.btn:hover {
		color: #349ef9;
		border: 1px solid #349ef9;
	}

	.left-btn {}

	.right-btn {
		z-index: 99;
	}

	.tags-close-box {
		box-sizing: border-box;
		padding-top: 3px;
		text-align: center;
		width: 30px;
		height: 40px;
		line-height: 32px;
		/* box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1); */
		z-index: 99;
		background-color: #FFFFFF;
	}

	a,
	a:hover,
	.router-link-active {
		text-decoration: none;
	}
</style>
